'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card"
import { BarChart2, TrendingUp, LogOut, Settings, X, Menu, Loader2 } from 'lucide-react'
import { signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app'
import { auth } from '@/utils/firebase';
import { getPortalUrl } from '@/utils/processPayment';
import { firebaseConfig } from '../utils/firebase.config';
import WelcomePopup from '../hooks/InstallPrompt';

import AlgosLogo from '../images/Algos Logo.png'


const app = initializeApp(firebaseConfig);

export default function DashboardPage() {
  // const [isLoading, setIsLoading] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false)
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsSubscriptionLoading(true)
      const portalUrl = await getPortalUrl(app);
      window.open(portalUrl, "_blank");
    } catch (error) {
      console.error("Error getting portal URL: ", error);
    } finally {
      setIsSubscriptionLoading(false)
    }
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <WelcomePopup />
      <header className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src={AlgosLogo} alt="Logo" className="h-9 w-auto mr-4"></img>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <Button variant="outline" className="flex items-center" onClick={handleManageSubscription} disabled={isSubscriptionLoading}>
              {isSubscriptionLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Loading...
                </>
              ) : (
                <>
                  <Settings className="mr-2 h-4 w-4" /> Manage Subscription
                </>
              )}
            </Button>
            <Button variant="outline" className="flex items-center" onClick={handleLogout}>
              <LogOut className="mr-2 h-4 w-4" /> Log Out
            </Button>
          </div>
          <div className="md:hidden">
            <Button variant="ghost" size="icon" onClick={toggleMenu}>
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </Button>
          </div>
        </div>
      </header>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden bg-white px-4 py-2 shadow-lg"
          >
            <Button 
              variant="outline" 
              className="w-full mb-2 flex items-center justify-center" 
              onClick={handleManageSubscription}
              disabled={isSubscriptionLoading}
            >
              {isSubscriptionLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Loading...
                </>
              ) : (
                <>
                  <Settings className="mr-2 h-4 w-4" /> Manage Subscription
                </>
              )}
            </Button>
            <Button variant="outline" className="w-full flex items-center justify-center" onClick={handleLogout}>
              <LogOut className="mr-2 h-4 w-4" /> Log Out
            </Button>
          </motion.div>
        )}
      </AnimatePresence>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Welcome to Your Sports Analytics Dashboard</h1>
          
          <div className="grid md:grid-cols-2 gap-8">
          <Card className="w-full">
              <CardHeader>
                <CardTitle className="flex items-center">
                  <TrendingUp className="mr-2 h-6 w-6 text-blue-600" />
                  Game Predictions
                </CardTitle>
                <CardDescription>Get AI-powered game predictions</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="mb-4">Explore our advanced algorithms' predictions for upcoming games, helping you make informed decisions.</p>
                <Link to="/predictions">
                  <Button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">View Predictions</Button>
                </Link>
              </CardContent>
            </Card>

            <Card className="w-full">
              <CardHeader>
                <CardTitle className="flex items-center">
                  <BarChart2 className="mr-2 h-6 w-6 text-blue-600" />
                  Statistical Analysis
                </CardTitle>
                <CardDescription>Dive deep into sports data and trends</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="mb-4">Access comprehensive statistical analysis for various sports, including player performance, team statistics, and historical data.</p>
                <Link to="/analysis">
                  <Button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">View Statistics</Button>
                </Link>
              </CardContent>
            </Card>
          </div>
        </motion.div>
      </main>
    </div>
  )
}